import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Hero from '../../../components/Hero/Hero';
import SEO from '../../../components/SEO/SEO';
import Link from '../../../components/Link/Link';
import Micro from '../../../layouts/Micro';

const Categories = ({ data, location }) => {
  const appTags = [
    ...new Set(data.allAppMarketplaceJson.edges.map(({ node }) => String(node.app_tag))),
  ];

  return (
    <Micro>
      <SEO title="App Marketplace Categories" />

      <Hero
        title="App Marketplace Categories"
        location={location}
        crumbLabel="App Marketplace Categories"
        isNeedMainLabel={false}
      />
      <div className="container my-1">
        <div className="row flex-center text-center my-1">
          <div className="container my-1">
            <div className="row flex-center text-center my-1">
              {appTags.map((tag) => (
                <div
                  className="col-lg-2 col-md-2 col-sm-3 col-xs-6"
                  key={Math.random().toString(36).substr(2, 9)}
                >
                  <div className="tagListItem p-2 mb-3 m-1">
                    <Link href={`/app-marketplace/${tag.toLowerCase()}`}>
                      <p className="tagListItem-text text-bold text-capitalize text-dark my-1">
                        {tag.replace(/-/g, ' ')}
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Micro>
  );
};

export default Categories;

Categories.defaultProps = {
  location: {},
};

Categories.propTypes = {
  data: PropTypes.objectOf.isRequired,
  location: PropTypes.objectOf,
};

// Let's create tag system with use Wordpress
export const pageQuery = graphql`
  query GetAppCategories {
    allAppMarketplaceJson(filter: { app_is_ready: { eq: true }, app_is_www: { eq: true } }) {
      edges {
        node {
          app_tag
        }
      }
    }
  }
`;
