import React from 'react';
import PropTypes from 'prop-types';
import CustomBreadcrumb from '../CustomBreadcrumb/CustomBreadcrumb';
import './hero.scss';

const Hero = ({ title, location, isNeedMainLabel }) => (
  <div className="hero">
    <div className="container text-center">
      {location && <CustomBreadcrumb location={location} centered />}
      <h1 className="my-1 text-capitalize">
        {isNeedMainLabel && 'RemoteTeam.com'} {title}
      </h1>
    </div>
  </div>
);

Hero.defaultProps = {
  isNeedMainLabel: false,
  location: {},
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  isNeedMainLabel: PropTypes.bool,
};

export default Hero;
